import { RouteName } from "@/constants";

export const menu = [
  // {
  //   title: "Главная",
  //   link: "/",
  // },
  // {
  //   title: "О центре",
  //   link: "",
  // },
  // {
  //   title: "Новости",
  //   link: "",
  // },
  // {
  //   title: "Контакты",
  //   link: "",
  // },
  {
    title: "Магистратура",
    link: RouteName.HOME_MAGISTRACY,
  },
  {
    title: "Ординатура",
    link: RouteName.HOME_ORDINAT,
  },
  {
    title: "Аспирантура",
    link: RouteName.COURSES_ASPIRANT,
  },
  {
    title: "Курсы ДПО",
    link: RouteName.COURSES_DPO,
  },
];
