<template>
  <header class="magistracy-header">
    <nav class="navbar">
      <router-link class="navbar-brand" :to="{ name: RouteName.HOME }">
        <svg class="magistracy__logo">
          <use xlink:href="#logo-white-icon--sprite" />
        </svg>
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#magistracyMenu"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse magistracy__nav" id="magistracyMenu">
        <ul class="navbar-nav">
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY }"
            >
              Магистратура
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_ABOUT,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_ABOUT }"
            >
              О программе
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_PROGRAM,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_PROGRAM }"
            >
              Структура программы
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY__ENTRANCE,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY__ENTRANCE }"
            >
              Приемная кампания
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_DOCUMENTS,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_DOCUMENTS }"
            >
              Документы
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_CONTACTS,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_CONTACTS }"
            >
              Контакты
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
  <main>
    <div class="magistracy-page">
      <div class="container">
        <div class="page-content">
          <h1 class="page-title">Контакты</h1>
          <div class="row">
            <div class="col-12 col-md-6 mb-4">
              <div class="expert-card">
                <img
                  :src="magistracyData?.contacts?.manager?.manager_photo"
                  class="card-image manager-photo"
                  alt="Толстякова Ольга Валентиновна"
                />
                <div class="card-body">
                  <div class="card-title">
                    {{ magistracyData?.contacts?.manager?.fullname }}
                  </div>
                  <div class="card-text">
                    {{ magistracyData?.contacts?.manager?.desc }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="mb-5">
                <p class="h5 font-weight-bolder mb-2">E-mail</p>
                <p
                  class="mb-2"
                  v-for="email in magistracyData?.contacts?.emails"
                  :key="email"
                >
                  <a :href="`mailto:${email}`">
                    {{ email }}
                  </a>
                </p>
              </div>
              <div class="mb-5">
                <p class="h5 font-weight-bolder mb-2">Телефон</p>
                <p
                  class="mb-2"
                  v-for="phone in magistracyData?.contacts?.phones"
                  :key="phone"
                >
                  {{ phone }}
                </p>
              </div>
              <div>
                <p class="h5 font-weight-bolder mb-23">Адрес</p>
                <p>{{ magistracyData?.contacts?.address }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <iframe
      src="https://yandex.ru/map-widget/v1/?lang=ru_RU&amp;scroll=true&amp;source=constructor-api&amp;um=constructor%3Ab70ef7869befc0f49131768294ed0fe796360165c0421a7d3d72ae9b9b2e6cc9"
      frameborder="0"
      allowfullscreen="true"
      width="100%"
      height="450"
      class="d-block"
    ></iframe>
  </main>
  <footer class="footer-magistracy">
    <div class="container">
      <div class="contacts-title">Приемная комиссия</div>
      <div class="contacts">
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#clock--sprite" />
            </svg>
          </div>
          <div class="contact-name">Часы работы</div>
          <div class="contact-text">
            <p>{{ magistracyData?.contacts?.works_time }}</p>
          </div>
        </div>
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#email--sprite" />
            </svg>
          </div>
          <div class="contact-name">Почта</div>
          <div class="contact-text">
            <p v-for="email in magistracyData?.contacts?.emails" :key="email">
              <a :href="`mailto:${email}`">
                {{ email }}
              </a>
            </p>
          </div>
        </div>
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#phone--sprite" />
            </svg>
          </div>
          <div class="contact-name">Телефон</div>
          <div class="contact-text">
            <p v-for="phone in magistracyData?.contacts?.phones" :key="phone">
              {{ phone }}
            </p>
          </div>
        </div>
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#map--sprite" />
            </svg>
          </div>
          <div class="contact-name">Адрес</div>
          <div class="contact-text">
            <p>
              {{ magistracyData?.contacts?.address }}
            </p>
            <p>
              <router-link :to="{ name: RouteName.HOME_MAGISTRACY_CONTACTS }">
                Карта
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="footer__copyright">&copy; ГБУ «НИИОЗММ ДЗМ», 2024</div>
  </footer>
  <div class="modal fade" id="magistracyRegister" tabindex="-1">
    <div class="modal-dialog magistracy-register">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
          <h3 class="modal-title">Подать заявку</h3>

          <div class="register-intro js-register-types">
            <div class="buttons-group">
              <div class="buttons-item">
                <button
                  type="button"
                  class="btn btn-register"
                  data-tab="#personRegister"
                >
                  <svg class="svg-icon">
                    <use xlink:href="img/sprite.svg#person"></use>
                  </svg>
                  <div class="card-body">Лично</div>
                </button>
              </div>
              <div class="buttons-item">
                <button
                  type="button"
                  class="btn btn-register"
                  data-tab="#webformRegister"
                >
                  <svg class="svg-icon">
                    <use xlink:href="img/sprite.svg#webform"></use>
                  </svg>
                  <div class="card-body">
                    Через Личный кабинет на&nbsp;сайте
                  </div>
                </button>
              </div>
              <div class="buttons-item">
                <button
                  type="button"
                  class="btn btn-register"
                  data-tab="#postalRegister"
                >
                  <svg class="svg-icon">
                    <use xlink:href="img/sprite.svg#postal"></use>
                  </svg>
                  <div class="card-body">Через операторов почтовой связи</div>
                </button>
              </div>
            </div>
          </div>

          <div class="register-tabs js-register-tabs">
            <div class="nav">
              <a class="nav-link" data-toggle="tab" href="#personRegister">
                <svg class="svg-icon">
                  <use xlink:href="img/sprite.svg#person"></use>
                </svg>
                Лично
              </a>
              <a class="nav-link" data-toggle="tab" href="#webformRegister">
                <svg class="svg-icon">
                  <use xlink:href="img/sprite.svg#webform"></use>
                </svg>
                На сайте
              </a>
              <a class="nav-link" data-toggle="tab" href="#postalRegister">
                <svg class="svg-icon">
                  <use xlink:href="img/sprite.svg#postal"></use>
                </svg>
                По почте
              </a>
            </div>
            <div class="tab-content">
              <div class="tab-pane" id="personRegister" role="tabpanel">
                <p>
                  <b>Вы можете подать документы лично по адресу:</b>
                  г.&nbsp;Москва, ул.&nbsp;Киевская, д.&nbsp;20, кабинет&nbsp;8.
                </p>
                <p><b>Часы работы:</b> Пн-Пт 09:00 – 17:30</p>
                <p>
                  <b
                    >Список документов для поступления на&nbsp;программу
                    магистратуры:</b
                  >
                </p>
                <ol>
                  <li>
                    <a
                      href="https://niioz.ru/upload/iblock/000/000ec0855d5336e3befb60fcf61f82a1.docx"
                      tarhet="_blank"
                      >Заявление на обучение</a
                    >
                  </li>
                  <li>
                    Копия паспорта (главная и регистрация по&nbsp;месту
                    жительства)
                  </li>
                  <li>
                    Копия диплома о высшем образовании с&nbsp;приложениями
                  </li>
                  <li>Копия СНИЛС</li>
                  <li>ИНН (копия или номер)</li>
                  <li>
                    <a
                      href="https://niioz.ru/upload/iblock/1b0/1b02e815b990b92a660bf53a78f855f2.docx"
                      target="_blank"
                      >Согласие на обработку персональных данных</a
                    >
                  </li>
                </ol>
                <div class="register-footer">
                  <button type="button" class="btn" data-dismiss="modal">
                    Понятно
                  </button>
                </div>
              </div>

              <div class="tab-pane" id="webformRegister" role="tabpanel">
                <p>
                  Для подачи документов в магистратуру Вы будете перенаправлены
                  на сайт Государственного бюджетного учреждения
                  Научно-исследовательский институт организации здравоохранения
                  и медицинского менеджмента Департамента здравоохранения г.
                  Москвы (ГБУ «НИИОЗММ ДЗМ»).
                </p>
                <ol class="divided">
                  <li>
                    Зарегистрируйтесь на сайте
                    <a href="https://niioz.ru/" target="_blank">www.niioz.ru</a>
                  </li>
                  <li>Подтвердите электронную почту по ссылке из письма</li>
                  <li>
                    Войдите в Личный кабинет в раздел
                    <a
                      href="https://niioz.ru/personal/magistracy/"
                      target="_blank"
                      >«Подать документы в магистратуру»</a
                    >
                  </li>
                  <li>Заполните форму, нажмите кнопку «Сохранить»</li>
                </ol>
                <p>
                  <a href="#" target="_blank">Скачать подробную инструкцию</a>
                </p>
                <div class="register-footer">
                  <a
                    href="https://niioz.ru/obrazovanie/magistratura/"
                    class="btn"
                    target="_blank"
                    >Продолжить</a
                  >
                </div>
              </div>

              <div class="tab-pane" id="postalRegister" role="tabpanel">
                <p>
                  Вы можете направить документы через оператора почтовой связи,
                  например, Почтой России.
                </p>
                <p>
                  <b>Адрес:</b> ул. Большая Татарская, д.&nbsp;30, помещение
                  1/2, Москва, 115184.
                </p>
                <p>
                  <b
                    >Список документов для поступления на&nbsp;программу
                    магистратуры:</b
                  >
                </p>
                <ol>
                  <li>
                    <a
                      href="https://niioz.ru/upload/iblock/000/000ec0855d5336e3befb60fcf61f82a1.docx"
                      target="_blank"
                      >Заявление на обучение</a
                    >
                  </li>
                  <li>
                    Копия паспорта (главная и регистрация по&nbsp;месту
                    жительства)
                  </li>
                  <li>
                    Копия диплома о высшем образовании с&nbsp;приложениями
                  </li>
                  <li>Копия СНИЛС</li>
                  <li>ИНН (копия или номер)</li>
                  <li>
                    <a
                      href="https://niioz.ru/upload/iblock/1b0/1b02e815b990b92a660bf53a78f855f2.docx"
                      target="_blank"
                      >Согласие на обработку персональных данных</a
                    >
                  </li>
                </ol>
                <div class="register-footer">
                  <button type="button" class="btn" data-dismiss="modal">
                    Понятно
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
} from "vue";
import { RouteName } from "@/constants";
import { useRoute } from "vue-router";
import store from "@/store";
import { useVfm } from "vue-final-modal";

export default defineComponent({
  name: "HomeMagistracyContactsPage",

  components: {},

  setup() {
    const vfm = useVfm();
    const route = useRoute();

    const magistracyData = computed(
      () =>
        store.getters.programsStore.magistracy?.length &&
        store.getters.programsStore.magistracy[0]
    );

    const setMainCss = () => {
      const link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.id = "main-css";

      document.head.appendChild(link);

      link.href = "/assets/css/main.css";
    };

    const removeMainCss = () => {
      document.getElementById("main-css")?.remove();
    };

    const openApplication = () => {
      vfm.open("modalApplication");
    };

    onBeforeMount(() => setMainCss());

    onBeforeUnmount(() => removeMainCss());

    onMounted(() => {
      store.dispatch.programsStore.getPrograms({ is_magistracy: true });
    });

    return {
      RouteName,
      route,
      magistracyData,
      openApplication,
    };
  },
});
</script>

<style lang="stylus" scoped>
.magistracy__logo {
  width: 206px;
  height: 40px;
  min-width: 206px;
}
.navbar-collapse {
  background-color: transparent;
}
.magistracy-page::before {
  background-image: url("@/assets/img/magistracy-hand.png");
}
.manager-photo {
  width: 100%;
  height: 100%;
  background-color: $colorFiller;
}
.magistracy__nav {
  position: static;
}
.navbar {
  gap: 20px;
}
.magistracy-intro {
  padding-top: 100px;
}
</style>
