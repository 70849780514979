<template>
  <header class="magistracy-header">
    <nav class="navbar">
      <router-link class="navbar-brand" :to="{ name: RouteName.HOME }">
        <svg class="magistracy__logo">
          <use xlink:href="#logo-white-icon--sprite" />
        </svg>
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#magistracyMenu"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse magistracy__nav" id="magistracyMenu">
        <ul class="navbar-nav">
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY }"
            >
              Магистратура
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_ABOUT,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_ABOUT }"
            >
              О программе
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_PROGRAM,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_PROGRAM }"
            >
              Структура программы
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY__ENTRANCE,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY__ENTRANCE }"
            >
              Приемная кампания
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_DOCUMENTS,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_DOCUMENTS }"
            >
              Документы
            </router-link>
          </li>
          <li
            :class="[
              'nav-item',
              {
                active: route?.name === RouteName.HOME_MAGISTRACY_CONTACTS,
              },
            ]"
          >
            <router-link
              class="nav-link"
              :to="{ name: RouteName.HOME_MAGISTRACY_CONTACTS }"
            >
              Контакты
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
  <main>
    <section class="magistracy-intro">
      <div class="magistracy-intro__body">
        <h1>Магистратура</h1>
        <div class="intro__lead">
          {{ magistracyData?.title }}
        </div>
        <div class="intro__action">
          <button class="btn" @click="openApplication">Подать заявку</button>
        </div>

        <div class="intro__invite">
          <div class="intro-title">
            Развивайтесь в цифровой модели здравоохранения Москвы
          </div>
          <div class="row">
            <div class="invite-col">
              <img src="@/assets/img/invite-point.svg" alt="" />
              <span>Постройте карьеру в&nbsp;IT в&nbsp;здравоохранении</span>
            </div>
            <div class="invite-col">
              <img src="@/assets/img/invite-point.svg" alt="" />
              <span>Выбирайте новый уровень в&nbsp;профессии</span>
            </div>
            <div class="invite-col">
              <img src="@/assets/img/invite-point.svg" alt="" />
              <span>Станьте лидерами в&nbsp;цифровизации здравоохранения</span>
            </div>
          </div>
        </div>

        <div class="intro__advantages">
          <div class="intro-title">Преимущества</div>
          <div class="advantages-row">
            <div
              class="advantages-col"
              v-for="item in magistracyData?.advantages?.main"
              :key="item"
            >
              <div class="advantage-card">
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="magistracy-begin">
      <h2 class="magistracy-title">
        Начало приема документов –
        {{ magistracyData?.reception_company?.start_reception_documents }}
      </h2>
      <div class="magistracy-divider"></div>
      <div class="begin__row">
        <div
          class="begin__item"
          v-for="item in magistracyData?.how_learn_going"
          :key="item.title"
        >
          {{ item?.title }}
        </div>
      </div>
      <button class="btn btn-magistracy" @click="openApplication">
        Подать заявку
      </button>
    </section>
    <section class="magistracy-skills">
      <div class="skills__body">
        <h2 class="magistracy-title">Вы научитесь</h2>
        <div class="skills__row">
          <div
            class="skills__item"
            v-for="item in magistracyData?.skills"
            :key="item"
          >
            <div class="skill">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="magistracy-prof">
      <h2 class="magistracy-title">
        Профессиональные возможности после окончания программы
      </h2>
      <div class="row">
        <div
          class="prof__item"
          v-for="(item, i) in magistracyData?.career"
          :key="item?.title"
        >
          <div class="prof-card">
            <svg class="magistracy__icon" v-if="i === 0">
              <use xlink:href="#admin--sprite" />
            </svg>
            <svg class="magistracy__icon" v-else-if="i === 1">
              <use xlink:href="#analyst--sprite" />
            </svg>
            <svg class="magistracy__icon" v-else>
              <use xlink:href="#inform--sprite" />
            </svg>
            {{ item?.title }}
          </div>
        </div>
      </div>
    </section>
    <section class="magistracy-program">
      <h2 class="magistracy-title">Структура образовательной программы</h2>
      <div class="program__body">
        <div class="accordion" id="accordionProgram">
          <div
            class="card semester"
            v-for="(item, i) in magistracyData?.plan?.list"
            :key="`semester-${i}`"
          >
            <div class="semester__head">
              <div
                :class="[
                  'semester-toggler',
                  { collapsed: hasCollapsed(i + 1).value },
                ]"
                data-toggle="collapse"
                data-target="#semester1"
                :aria-expanded="hasCollapsed(i + 1).value"
                @click="setCollapsedId(i + 1)"
              >
                <div class="semester-name">{{ item?.period?.name }}</div>
                <div class="semester-date">{{ item?.period?.date }}</div>
              </div>
            </div>
            <div
              :class="[
                'collapse',
                'semester__body',
                {
                  show: hasCollapsed(i + 1).value,
                },
              ]"
              id="semester1"
              data-parent="#accordionProgram"
            >
              <div class="semester-program">
                <ul class="circle-list">
                  <li v-for="discipline in item?.disciplines" :key="discipline">
                    {{ discipline }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="magistracy-entrance">
      <div class="entrance__body">
        <h2 class="magistracy-title">Поступление в датах</h2>
        <div class="row">
          <div
            class="entrance__item"
            v-for="item in magistracyData?.reception_company?.reception_bar_2"
            :key="item.title"
          >
            <div class="entrance-card">
              <div class="entrance-name">{{ item?.title }}</div>
              <div class="entrance-date">
                {{ item?.date }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="magistracy-steps">
      <div class="admission__action">
        <button class="btn btn-magistracy" @click="openApplication">
          Подать заявку
        </button>
      </div>

      <div class="admission__row">
        <div class="admission__item">
          <div class="admission-name">старт обучения</div>
          <div class="admission-text">1 сентября 2024&nbsp;г.</div>
        </div>
        <div class="admission__item">
          <div class="admission-name">Формат обучения</div>
          <div class="admission-text">Онлайн</div>
        </div>
        <div class="admission__item">
          <div class="admission-name">срок обучения</div>
          <div class="admission-text">2 года</div>
        </div>
        <div class="admission__item">
          <div class="admission-name">количество мест</div>
          <div class="admission-text">10</div>
        </div>
        <div class="admission__item">
          <div class="admission-name">документ государственного образца*</div>
          <div class="admission-text">
            <small
              >*после прохождения процедуры государственной аккредитации</small
            >
          </div>
        </div>
      </div>
      <div class="magistracy-divider"></div>

      <div class="steps">
        <h2 class="steps-title">Поступите в 2 шага</h2>
        <div class="row">
          <div
            class="steps__item"
            v-for="(item, i) in magistracyData?.reception_company
              ?.reception_bar_1"
            :key="item?.title"
          >
            <div class="step-num">{{ `0${i + 1}` }}</div>
            <div class="step-text">
              <div class="step-name">{{ item?.title }}</div>
              <ul class="circle-list">
                <li
                  v-for="reception in item?.list"
                  :key="reception"
                  v-html="reception"
                />
              </ul>
            </div>
          </div>
        </div>

        <button class="btn btn-magistracy" @click="openApplication">
          Подать заявку
        </button>
      </div>
    </section>
    <div class="magistracy-board">
      <div class="container">
        <div class="board__body">
          <div class="board__row">
            <div class="board__item">
              <a
                href="https://onlinelearning.niioz.ru/assets/files/magistr/schedule-1.pdf"
                class="board-card"
                target="_blank"
              >
                <svg class="svg-icon">
                  <use xlink:href="@/assets/img/sprite.svg#docs1"></use>
                </svg>
                <div class="card-text">Расписание вступительных испытаний</div>
              </a>
            </div>
            <div class="board__item">
              <a
                href="https://onlinelearning.niioz.ru/assets/files/magistr/docs-23.08.24.pdf"
                class="board-card"
                target="_blank"
              >
                <svg class="svg-icon">
                  <use xlink:href="@/assets/img/sprite.svg#docs2"></use>
                </svg>
                <div class="card-text">
                  Информация о количестве поданных заявлений
                </div>
              </a>
            </div>
            <div class="board__item">
              <a
                href="https://onlinelearning.niioz.ru/assets/files/magistr/docs-list-23.08.24.pdf"
                class="board-card"
                target="_blank"
              >
                <svg class="svg-icon">
                  <use xlink:href="@/assets/img/sprite.svg#docs3"></use>
                </svg>
                <div class="card-text">Списки лиц, подавших документы</div>
              </a>
            </div>
            <div class="board__item">
              <a
                href="https://onlinelearning.niioz.ru/assets/files/magistr/results-27.08.2024.pdf"
                class="board-card"
                target="_blank"
              >
                <svg class="svg-icon">
                  <use xlink:href="@/assets/img/sprite.svg#docs4"></use>
                </svg>
                <div class="card-text">Результаты вступительного испытания</div>
              </a>
            </div>
            <div class="board__item">
              <a
                href="https://onlinelearning.niioz.ru/assets/files/magistr/ranked-list-27.08.2024.pdf"
                class="board-card"
                target="_blank"
              >
                <svg class="svg-icon">
                  <use xlink:href="@/assets/img/sprite.svg#docs5"></use>
                </svg>
                <div class="card-text">Ранжированный список поступающих</div>
              </a>
            </div>
            <div class="board__item">
              <a
                href="https://onlinelearning.niioz.ru/magistr#"
                class="board-card"
                target="_blank"
              >
                <svg class="svg-icon">
                  <use xlink:href="@/assets/img/sprite.svg#docs6"></use>
                </svg>
                <div class="card-text">Сведения о зачислении на обучение</div>
              </a>
            </div>
          </div>

          <button class="btn btn-magistracy" @click="openApplication">
            Подать заявку
          </button>
        </div>
      </div>
    </div>
    <section class="magistracy-documents">
      <div class="container">
        <h2 class="magistracy-title">Документы</h2>
        <div class="accordion">
          <div
            class="card docs__item"
            v-for="item in magistracyData?.documents"
            :key="item.title"
          >
            <div class="docs__head">
              <div
                class="docs-toggler"
                data-toggle="collapse"
                data-target="#docsEntrance"
                aria-expanded="true"
              >
                {{ item.title }}
              </div>
            </div>
            <div class="collapse show docs__body" id="docsEntrance">
              <div class="card-body">
                <ul class="docs-list">
                  <li v-for="(doc, i) in item?.list" :key="i" v-html="doc" />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="magistracy-gift">
      <div class="gift__row">
        <div class="gift__col">
          <h2>Подарок каждому</h2>
          <p>
            Первый в столичном здравоохранении ИИ словарь профессиональных
            терминов
          </p>
        </div>
        <div class="gift__col">
          <img
            src="@/assets/img/gift.png"
            alt="ИИ словарь профессиональных терминов"
          />
        </div>
      </div>
    </section>
  </main>
  <footer class="footer-magistracy">
    <div class="container">
      <div class="contacts-title">Приемная комиссия</div>
      <div class="contacts">
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#clock--sprite" />
            </svg>
          </div>
          <div class="contact-name">Часы работы</div>
          <div class="contact-text">
            <p>{{ magistracyData?.contacts?.works_time }}</p>
          </div>
        </div>
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#email--sprite" />
            </svg>
          </div>
          <div class="contact-name">Почта</div>
          <div class="contact-text">
            <p v-for="email in magistracyData?.contacts?.emails" :key="email">
              <a :href="`mailto:${email}`">
                {{ email }}
              </a>
            </p>
          </div>
        </div>
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#phone--sprite" />
            </svg>
          </div>
          <div class="contact-name">Телефон</div>
          <div class="contact-text">
            <p v-for="phone in magistracyData?.contacts?.phones" :key="phone">
              {{ phone }}
            </p>
          </div>
        </div>
        <div class="contacts__item">
          <div class="contact-icon">
            <svg class="magistracy__icon">
              <use xlink:href="#map--sprite" />
            </svg>
          </div>
          <div class="contact-name">Адрес</div>
          <div class="contact-text">
            <p>
              {{ magistracyData?.contacts?.address }}
            </p>
            <p>
              <router-link :to="{ name: RouteName.HOME_MAGISTRACY_CONTACTS }">
                Карта
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="footer__copyright">&copy; ГБУ «НИИОЗММ ДЗМ», 2024</div>
  </footer>
</template>

<script lang="ts">
import {
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref,
  computed,
} from "vue";
import store from "@/store";
import { RouteName } from "@/constants";
import { useRoute } from "vue-router";
import { formatDate } from "@/utils/formatDate";
import { useVfm } from "vue-final-modal";

import "@/assets/img/icons/admin.svg?sprite";
import "@/assets/img/icons/analyst.svg?sprite";
import "@/assets/img/icons/inform.svg?sprite";
import "@/assets/img/icons/clock.svg?sprite";
import "@/assets/img/icons/email.svg?sprite";
import "@/assets/img/icons/phone.svg?sprite";
import "@/assets/img/icons/map.svg?sprite";

export default defineComponent({
  name: "HomeMagistracyPage",

  components: {},

  setup() {
    const vfm = useVfm();
    const route = useRoute();

    const pickedValue = ref<number>(1);

    const hasCollapsed = (id: number) =>
      computed(() => pickedValue.value === id);

    const magistracyData = computed(
      () =>
        store.getters.programsStore.magistracy?.length &&
        store.getters.programsStore.magistracy[0]
    );

    const setMainCss = () => {
      const link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.id = "main-css";

      document.head.appendChild(link);

      link.href = "/assets/css/main.css";
    };

    const removeMainCss = () => {
      document.getElementById("main-css")?.remove();
    };

    const setCollapsedId = (id: number) => (pickedValue.value = id);

    const openApplication = () => {
      vfm.open("modalApplication");
    };

    document.body.classList.remove("courses");

    onBeforeMount(() => setMainCss());

    onBeforeUnmount(() => removeMainCss());

    onMounted(() => {
      store.dispatch.programsStore.getPrograms({ is_magistracy: true });
    });

    return {
      formatDate,
      magistracyData,
      RouteName,
      route,
      pickedValue,
      openApplication,
      hasCollapsed,
      setCollapsedId,
    };
  },
});
</script>

<style lang="stylus" scoped>
.magistracy-intro::before {
  background-image: url("@/assets/img/magistracy-intro.png");
}
.magistracy-skills:before {
  background-image: url("@/assets/img/magistracy-bg.png");
}
.magistracy__logo {
  width: 206px;
  height: 40px;
  min-width: 206px;
}
.magistracy__nav {
  position: static;
}
.navbar {
  gap: 20px;
}
.magistracy-intro {
  padding-top: 100px;
}
.navbar-collapse {
  background-color: transparent;
}
.docs-list li::before {
  content: "";
  width: 11px;
  height: 11px;
  border-radius: 100px;
  background-color: #E24E25;
  position: relative;
  top: 2px;
  left: -2px;
}
.magistracy-documents::before {
  background-image: url("@/assets/img/robot.png");
}
.program__body::before {
  background-image: url("@/assets/img/hand.png")!important;
}
</style>

<style lang="stylus">
.magistracy-page .container {
  padding-top: 82px;
}
</style>
