<template>
  <VueFinalModal
    class="dpo"
    v-model="hasOpenModal"
    name="modalApplication"
    modal-id="modalApplication"
    title="Подать заявку на обучение"
    :zIndexFn="() => 1000"
  >
    <section class="application" id="application">
      <div class="container">
        <h2 class="section-title">Подать заявку на обучение</h2>
        <form @submit.prevent="sendForm">
          <div class="application-row">
            <div class="application-col">
              <input
                v-model="formModel.name"
                type="text"
                class="form-control"
                placeholder="Имя *"
              />
            </div>
            <div class="application-col">
              <input
                v-model="formModel.phone"
                type="text"
                class="form-control"
                placeholder="Телефон *"
              />
            </div>
            <div class="application-col">
              <input
                v-model="formModel.email"
                type="email"
                class="form-control"
                placeholder="Email *"
              />
            </div>
          </div>
          <div class="application-row">
            <div class="form-checkbox">
              <div class="form-check">
                <input
                  v-model="formModel.is_organization"
                  class="form-check-input"
                  type="checkbox"
                  id="judicial"
                />
                <label class="form-check-label" for="judicial">
                  Я юридическое лицо
                </label>
              </div>
              <div class="form-check">
                <input
                  v-model="isConfidencial"
                  class="form-check-input"
                  type="checkbox"
                  id="privacy"
                  checked
                />
                <label class="form-check-label form__agree" for="privacy">
                  Я согласен с условиями
                  <a
                    href="https://ol-test.api.niioz.ru/storage/264/politics_pd.pdf"
                    style="color: #fff; font-size: inherit"
                    target="_blank"
                  >
                    политики обработки персональных данных
                  </a>
                  и даю согласие на обработку персональных данных
                </label>
              </div>
            </div>
            <div class="form-submit">
              <CommonButtonPrimary>Оставить заявку</CommonButtonPrimary>
            </div>
          </div>
        </form>
      </div>
    </section>
  </VueFinalModal>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import { basicFetch } from "@/store/storeUtils";
import { api } from "@/utils/apiInstance";
import { Statuses } from "@/constants/statuses";
import { useToast } from "vue-toastification";
import { VueFinalModal } from "vue-final-modal";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";

export default defineComponent({
  name: "CommonApplication",

  components: {
    VueFinalModal,
    CommonButtonPrimary,
  },

  setup(props) {
    const toast = useToast();

    const formModel = reactive({
      name: "",
      phone: "",
      email: "",
      is_organization: false,
    });

    const hasOpenModal = ref(false);

    const isConfidencial = ref(false);

    const toggleEnrollments = async () => {
      await basicFetch({
        method: api.sendPost,
        props: {
          url:
            window.location.hostname === "localhost"
              ? `/api/enrollments`
              : `/backend/api/enrollments`,
          data: {
            degree_id: 3,
            ...formModel,
          },
        },
        setState: ({ fetchState }) => {
          console.log(fetchState);
        },
        callback: async (fetchState) => {
          if (fetchState === Statuses.FULFILLED) {
            formModel.name = "";
            formModel.email = "";
            formModel.phone = "";
            formModel.is_organization = false;
            isConfidencial.value = false;
            toast.success("Заявка принята");
          }
        },
      });
    };

    const sendForm = () => {
      if (!isConfidencial.value) {
        toast.error(
          "Необходимо принять согласие с политикой конфиденциальности"
        );
      } else if (
        isConfidencial.value &&
        formModel.name &&
        formModel.phone &&
        formModel.email
      ) {
        toggleEnrollments();
      }
    };

    return {
      formModel,
      isConfidencial,
      hasOpenModal,
      sendForm,
    };
  },
});
</script>

<style lang="stylus" scoped>
.form {
  &__agree {
    max-width: 350px;
  }

  &-checkbox {
    text-align: left;
  }
}
.application {
  position: relative;
  top: 300px;
  border-radius: 20px;
  max-width: 80%;
  margin: auto;
}
</style>
