import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalSignIn = _resolveComponent("ModalSignIn")!
  const _component_ModalRegistration = _resolveComponent("ModalRegistration")!
  const _component_ModalApplication = _resolveComponent("ModalApplication")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ModalSignIn),
    _createVNode(_component_ModalRegistration),
    _createVNode(_component_ModalApplication),
    _createElementVNode("div", {
      class: _normalizeClass({ dpo: _ctx.hasDpo })
    }, [
      _createVNode(_component_router_view)
    ], 2)
  ], 64))
}