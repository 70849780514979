<template>
  <LayoutHomepage
    isHigher
    :emailsList="hasCourses ? emailsDpoList : emailsList"
    :phonesList="hasCourses ? phonesDpoList : phonesList"
  >
    <CommonIntro />

    <div class="page-section">
      <div class="nav-overflow">
        <nav class="tabs-menu">
          <ul class="nav nav-tabs">
            <li
              class="nav-item intro__nav"
              v-for="item in homeNavList"
              :key="item.label"
              v-show="
                !(
                  (item.label === 'Документы' ||
                    item.label === 'Приемная кампания') &&
                  hasCourses
                )
              "
            >
              <router-link
                :class="[
                  'nav-link',
                  {
                    active:
                      pathName(
                        item.pathOrdinatName,
                        item.pathAspirantName,
                        item.pathCoursesName
                      ).value === route?.name,
                  },
                ]"
                :to="{
                  name: pathName(
                    item.pathOrdinatName,
                    item.pathAspirantName,
                    item.pathCoursesName
                  ).value,
                  query: route.query,
                }"
                @click="toggleBodyStyle"
              >
                {{ item.label }}
              </router-link>
            </li>
          </ul>
        </nav>
      </div>

      <h2 class="section-title">{{ programOrd?.certificate?.type }}</h2>
      <div class="certificate">
        <div class="card-row">
          <div class="card-col">
            <img
              :src="programOrd?.media?.certificate_image?.url"
              class="card-image"
            />
          </div>
          <div class="card-col">
            <div class="card-text">
              <div
                class="certificate-item"
                v-for="(item, index) in programOrd?.certificate?.list"
                :key="item"
              >
                <div class="cert__iconBox">
                  <svg class="cert__icon" v-if="index === 0">
                    <use xlink:href="#cert-icon-0--sprite" />
                  </svg>
                  <svg class="cert__icon" v-else>
                    <use xlink:href="#cert-icon-1--sprite" />
                  </svg>
                </div>
                <div class="item-text">
                  <b>{{ item }}</b>
                </div>
              </div>
            </div>
            <div class="card-footer">
              {{ programOrd?.certificate?.details }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <CommonApplication :degree_id="degreeId" :program_id="programId" />
  </LayoutHomepage>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from "vue";
import { LayoutHomepage } from "@/layouts/LayoutHomepage";
import { CommonApplication } from "../HomeProgramsListPage/components/CommonApplication";
import { CommonIntro } from "./components/CommonIntro";
import { useRouter, useRoute } from "vue-router";
import {
  homeNavList,
  emailsList,
  emailsDpoList,
  phonesList,
  phonesDpoList,
} from "./constants";
import { RouteName } from "@/constants";
import store from "@/store";

import "@/assets/img/icons/cert-icon-0.svg?sprite";
import "@/assets/img/icons/cert-icon-1.svg?sprite";

export default defineComponent({
  name: "HomeCertPage",

  components: {
    LayoutHomepage,
    CommonApplication,
    CommonIntro,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();

    const programOrd = computed(() => store.getters.programsStore.data);
    const showDiscipline = ref(1);

    const hasOrdinat = computed(() => useRoute()?.path.includes("ordinat"));
    const hasAspirant = computed(() => route?.path.includes("aspirant"));
    const hasCourses = computed(() => route?.path.includes("dpo"));

    const bodyStyleName = computed(() => {
      if (route?.name === RouteName.HOME_ORDINAT_CERT) {
        return "ordinat";
      } else if (route?.name === RouteName.HOME_ASPIRANT_CERT) {
        return "aspirant";
      }
      return "courses";
    });

    const queryId = computed(() => route?.params?.id);

    const programId = computed(() => {
      if (queryId.value) return Number(queryId.value);

      if (route.path.includes("ordinat")) {
        return 1;
      } else if (route.path.includes("aspirant")) {
        return 2;
      }

      return 4;
    });

    const degreeId = computed(() => {
      if (route.path.includes("ordinat")) {
        return 1;
      } else if (route.path.includes("aspirant")) {
        return 2;
      }

      return 4;
    });

    const hasShowDisciplines = (id: number) =>
      computed(() => Number(route.query.id) === id);

    const goToDisciplines = (id: number) => {
      router.push({ query: { id } });
      showDiscipline.value = id;
    };

    const pathName = (ordinatPath: any, aspirantPath: any, coursesPath: any) =>
      computed(() => {
        if (hasOrdinat.value) {
          return ordinatPath;
        } else if (hasAspirant.value) {
          return aspirantPath;
        }
        return coursesPath;
      });

    const toggleBodyStyle = () => {
      document.body.classList.add(bodyStyleName.value);
    };

    onMounted(async () => {
      toggleBodyStyle();

      store.dispatch.programsStore.getPrograms({ program_id: programId.value });
    });

    return {
      programId,
      degreeId,
      hasCourses,
      programOrd,
      showDiscipline,
      homeNavList,
      route,
      emailsList,
      emailsDpoList,
      phonesList,
      phonesDpoList,
      toggleBodyStyle,
      pathName,
      goToDisciplines,
      hasShowDisciplines,
    };
  },
});
</script>

<style lang="stylus" scoped>
.header {
  &__icon {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }
}

.certificate-item {
  gap: 10px;
}

.cert__icon {
  width: 65px;
  height: 65px;
}

.cert__iconBox {
  width: 65px;
}
</style>
