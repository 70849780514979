<template>
  <section class="application" id="application">
    <div class="container">
      <h2 class="section-title">Подать заявку на обучение</h2>
      <form method="post">
        <div class="application-row">
          <div class="application-col">
            <input type="text" class="form-control" placeholder="Имя *" />
          </div>
          <div class="application-col">
            <input type="text" class="form-control" placeholder="Телефон *" />
          </div>
          <div class="application-col">
            <input type="email" class="form-control" placeholder="Email *" />
          </div>
        </div>
        <div class="application-row">
          <div class="form-checkbox">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="judicial" />
              <label class="form-check-label" for="judicial">
                Я юридическое лицо
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="privacy"
                checked
              />
              <label class="form-check-label form__agree" for="privacy">
                Я согласен с условиями
                <a
                  href="https://ol-test.api.niioz.ru/storage/264/politics_pd.pdf"
                  style="color: #fff; font-size: inherit"
                  target="_blank"
                >
                  политики обработки персональных данных
                </a>
                и даю согласие на обработку персональных данных
              </label>
            </div>
          </div>
          <div class="form-submit">
            <button class="btn" type="submit">Оставить заявку</button>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CommonApplication",

  components: {},
});
</script>

<style lang="stylus">
.form {
  &__agree {
    max-width: 350px;
  }
}
</style>
