<template>
  <footer class="footer-wrap">
    <div class="footer-bg"><div class="footer-bg-wrap" /></div>

    <div class="footer">
      <div :class="['footer__main', { footer__mainHigher: isHigher }]">
        <div class="footer__logoContainer">
          <svg class="footer__logo">
            <use xlink:href="#logo-white-icon--sprite" />
          </svg>
        </div>

        <div class="footer__content">
          <div class="footer__infoContainer" v-if="!isHigher">
            <a class="footer__link" v-for="link in links" :key="link">
              {{ link }}
            </a>
          </div>

          <div class="footer__socialsContainer">
            <a href="https://t.me/mmcito">
              <svg class="footer__icon">
                <use xlink:href="#telegram-white-icon--sprite" />
              </svg>
            </a>

            <a href="https://vk.com/niiozmm">
              <svg class="footer__icon">
                <use xlink:href="#vk-white-icon--sprite" />
              </svg>
            </a>
          </div>
        </div>
      </div>

      <div class="footer__contacts" v-if="isHigher">
        <div class="footer__contactsItem">
          <p class="footer__contactsTitle">Часы работы</p>

          <div class="footer__contactsList">
            <div>Пн-Пт 09:00 - 17:30</div>

            <div>Сб-Вс Выходные</div>
          </div>
        </div>

        <div class="footer__contactsItem">
          <p class="footer__contactsTitle">Почта</p>

          <div class="footer__contactsList">
            <a
              class="footer__contactsLink"
              :href="`mailto:${email}`"
              v-for="email in emailsList"
              :key="email"
            >
              {{ email }}
            </a>
          </div>
        </div>

        <div class="footer__contactsItem">
          <p class="footer__contactsTitle">Телефон</p>

          <div class="footer__contactsList">
            <div v-for="phone in phonesList" :key="phone">
              {{ phone }}
            </div>
          </div>
        </div>

        <div class="footer__contactsItem">
          <p class="footer__contactsTitle">Адрес</p>

          <div>1221165, г. Москва, ул. Киевская, д. 20</div>
        </div>
      </div>

      <div class="footer__under">
        <a
          href="https://niioz.ru/obrazovanie/svedeniya-ob-organizatsii-osushchestvlyayushchey-obrazovatelnuyu-deyatelnost/"
          target="_blank"
          class="footer__link"
          style="font-weight: 400"
        >
          Сведения об организации, осуществляющей образовательную деятельность
        </a>

        <a
          href="https://ol-test.api.niioz.ru/storage/264/politics_pd.pdf"
          target="_blank"
          class="footer__link"
          style="font-weight: 400"
        >
          Политика обработки персональных данных
        </a>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { HomeFooterProps } from "./types";

import "@/assets/img/icons/logo-white-icon.svg?sprite";
import "@/assets/img/icons/telegram-white-icon.svg?sprite";
import "@/assets/img/icons/youtube-white-icon.svg?sprite";
import "@/assets/img/icons/rutube-white-icon.svg?sprite";
import "@/assets/img/icons/vk-white-icon.svg?sprite";

export default defineComponent({
  name: "HomeFooter",
  components: {},

  props: {
    emailsList: {
      type: Array as PropType<HomeFooterProps["emailsList"]>,
    },
    phonesList: {
      type: Array as PropType<HomeFooterProps["phonesList"]>,
    },
    isHigher: {
      type: Boolean as PropType<HomeFooterProps["isHigher"]>,
    },
  },

  setup() {
    const links = ["О проекте", "Программы", "Контакты"];

    return {
      links,
    };
  },
});
</script>

<style lang="stylus" scoped>
.footer-wrap {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.footer-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-image: url("@/assets/img/footer-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  &-wrap {
    width: 100%;
    height: 100%;
    background-color: #0D171EE5;
  }
}

.footer {
  width: 100%;
  height: 100%;
  max-width: 1400px;
  top: 0;
  left: auto;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  text-align: center;
  z-index: 2;

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__under {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    gap: 12px;
    border-top: 1px solid #3C4850;
  }

  &__logo {
    width: 130px;
    height: 60px;
    max-width: none;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &__contactsTitle {
    color: #9CABB6;
  }

  &__contactsList {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__contactsLink {
    color: $colorFiller;
    text-decoration: none;
    getFontText();
  }

  &__infoContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__link {
    color: $colorFiller;
    font-weight: bold;
    text-decoration: none;
  }

  &__socialsContainer {
    display: flex;
    gap: .8vw;
  }

  &__icon {
    width: 35px;
    height: 35px;
  }

  +mediaTablet() {
    text-align: left;

    &__logo {
      width: 15vw;
    }

    &__content {
      gap: 1vw;
    }

    &__main {
      flex-direction: row;
      width: 100%;
    }

    &__mainHigher {

    }

    &__contacts {
      display: grid;
      grid-template-columns: repeat(2, 290px);
      justify-content: space-between;
      width: 100%;
    }

    &__under {
      width: 100%;
    }

    &__infoContainer {
      flex-direction: row;
      gap: 1.2vw;
    }
  }

  @media (min-width:1400px) {
    max-width: 1400px;

    &__content {
      flex-direction: row;
      gap: 80px;
    }

    &__contacts {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 44px;
    }

    &__infoContainer {
      gap: 69px;
    }
  }

  +mediaGiant() {
    font-size: 20px;
  }
}
</style>
