import { computed } from "vue";
import store from "@/store";
import { RouteName } from "@/constants";

const programOrd = computed(() => store.getters.programsStore.data);

export const homeNavList = computed(() => [
  {
    label: "О программе",
    pathOrdinatName: RouteName.HOME_ORDINAT,
    pathAspirantName: RouteName.HOME_ASPIRANT,
    pathCoursesName: RouteName.HOME_COURSES,
  },
  {
    label: "Учебный план",
    pathOrdinatName: RouteName.HOME_ORDINAT_PLAN,
    pathAspirantName: RouteName.HOME_ASPIRANT_PLAN,
    pathCoursesName: RouteName.HOME_COURSES_PLAN,
  },
  {
    label: programOrd.value?.certificate?.type || "Диплом",
    pathOrdinatName: RouteName.HOME_ORDINAT_CERT,
    pathAspirantName: RouteName.HOME_ASPIRANT_CERT,
    pathCoursesName: RouteName.HOME_COURSES_CERT,
  },
  {
    label: "Приемная кампания",
    pathOrdinatName: RouteName.HOME_ORDINAT_ADMISSION,
    pathAspirantName: RouteName.HOME_ASPIRANT_ADMISSION,
    pathCoursesName: RouteName.HOME_COURSES_ADMISSION,
  },
  // {
  //   label: "Документы",
  //   pathOrdinatName: RouteName.HOME_ORDINAT_DOCS,
  //   pathAspirantName: RouteName.HOME_ASPIRANT_DOCS,
  // },
  {
    label: "Отзывы",
    pathOrdinatName: RouteName.HOME_ORDINAT_REVIEWS,
    pathAspirantName: RouteName.HOME_ASPIRANT_REVIEWS,
    pathCoursesName: RouteName.HOME_COURSES_REVIEWS,
  },
  {
    label: "Ответы на вопросы",
    pathOrdinatName: RouteName.HOME_ORDINAT_FAQ,
    pathAspirantName: RouteName.HOME_ASPIRANT_FAQ,
    pathCoursesName: RouteName.HOME_COURSES_FAQ,
  },
]);

export const emailsList = [
  "TolstyakovaOV1@zdrav.mos.ru",
  "PerunovaEA@zdrav.mos.ru",
  "KuznetsovMY1@zdrav.mos.ru",
];

export const phonesList = [
  "8 (499) 249-74-72 (доб. 574б 572б 571)",
  "8 (925) 307-98-36",
  "8 (905) 734-17-14",
  "8 (977) 570-30-66",
];

export const emailsDpoList = [
  "BogatovaMV@zdrav.mos.ru",
  "GavrilinaMV@zdrav.mos.ru",
];

export const phonesDpoList = [
  "8 (499) 249-74-72 (доб. 563)",
  "8 (499) 249-74-72 (доб. 551)",
];
